import Menu from "../menu/Menu";
import MenuItem from "../menu/MenuItem";
import ProfileIcon from "../../../images/system/profile.svg";
import ProfileIconHover from "../../../images/system/profile-hover.svg";
import HistoryIcon from "../../../images/system/history.svg";
import HistoryIconHover from "../../../images/system/history-hover.svg";
import ExitIcon from "../../../images/system/exit.svg";
import ExitIconHover from "../../../images/system/exit-hover.svg";
import UserLogo from "../../../images/user-logo/Rectangle 77.png";
import ArrowIcon from "../../../images/arrows/arrow-slide.svg";
import { useAuth } from "../../../hooks/auth/auth.hook";
import { useContext } from "react";
import { AuthContext } from "../../../providers/AuthProvider";
import { BasketContext } from "../../../providers/BasketProvider";
import { useTranslation } from "react-i18next";

const Profile = () => {
    const { isAuth, setIsAuth } = useContext(AuthContext);
    const { isAdd, setIsAdd } = useContext(BasketContext);
    const {t} = useTranslation();

    return (
        <div className="navigation__item-profile">
            <div className="navigation__ava">
                <img src={UserLogo} alt="user logotype" />
            </div>
            <div className="navigation__button">
                <img src={ArrowIcon} alt="arrow slide down" />
            </div>
            <div className="navigation__menu">
                <Menu>
                    <MenuItem to={"/profile"} src={ProfileIcon} srcHover={ProfileIconHover} alt="profile" text={t('profile')} />
                    <MenuItem to={"/history"} src={HistoryIcon} srcHover={HistoryIconHover} alt="history of buy" text={t('history-buy')} />
                    <div className="menu__divider">
                        <div></div>
                    </div>
                    <MenuItem onClick={() => { localStorage.removeItem('token'); localStorage.removeItem('basketId'); localStorage.removeItem('basketItems'); setIsAuth(false); setIsAdd(false); }} src={ExitIcon} srcHover={ExitIconHover} alt="exit" text={t('logout')} />
                </Menu>
            </div>
        </div>
    )
}

export default Profile;