import { useTranslation } from "react-i18next";

const Policy = (props) => {
    const {t} = useTranslation();
    
    return (
        <main className="main">
            <div className="information">
                <div className="information__row">
                    <div className="news-title">{t('policy')}</div>
                    <div class="main-text">
                        {t('policy1')}

                    </div>
                    <div class="news-title space">{t('policy2')}</div>
                    <div class="main-text">{t('policy21')}</div>
                    <div class="main-text">{t('policy22')}</div>
                    <div class="main-text">{t('policy23')}</div>
                    <div class="main-text">{t('policy24')}</div>
                    <div class="main-text">
                    {t('policy25')}</div>
                    <div class="news-title space">{t('policy3')}</div>
                    <div class="main-text">{t('policy31')}</div>
                    <div class="main-text">{t('policy32')}</div>
                    <div class="main-text">{t('policy33')}</div>
                    <div class="main-text">{t('policy34')}</div>
                    <div class="main-text">{t('policy35')}</div>
                    <div class="main-text">{t('policy36')}</div>
                    <div class="news-title space">{t('policy4')}</div>
                    <div class="main-text">{t('policy41')}</div>
                    <div class="main-text">{t('policy42')}</div>
                    <div class="main-text">{t('policy43')}</div>
                    <div class="news-title space">{t('policy5')}</div>
                    <div class="main-text">{t('policy51')}</div>
                    <div class="main-text">{t('policy511')}</div>
                    <div class="main-text">{t('policy512')}</div>
                    <div class="main-text">{t('policy513')}</div>
                    <div class="main-text">{t('policy514')}</div>
                    <div class="main-text">{t('policy515')}</div>
                    <div class="main-text">{t('policy52')}</div>
                    <div class="main-text">{t('policy53')}</div>
                    <div class="main-text">{t('policy54')}</div>
                    <div class="main-text">{t('policy55')}</div>
                    <div class="news-title space">{t('policy6')}</div>
                    <div class="main-text">{t('policy61')}</div>
                    <div class="main-text">{t('policy62')}</div>
                    <div class="news-title space">{t('policy7')}</div>
                    <div class="main-text">{t('policy71')}</div>
                    <div class="main-text">{t('policy72')}</div>
                    <div class="main-text">{t('policy73')}</div>
                    <div class="main-text">{t('policy74')}</div>
                    <div class="main-text">{t('policy75')}</div>
                    <div class="main-text">{t('policy76')}</div>
                    <div class="main-text">{t('policy77')}</div>
                    <div class="news-title space">{t('policy8')}</div>
                    <div class="main-text">{t('policy81')}</div>
                    <div class="main-text">{t('policy82')}</div>
                    <div class="main-text">{t('policy83')}</div>
                    <div class="main-text">{t('policy84')}</div>
                    <div class="main-text">{t('policy85')}</div>
                    <div class="main-text">{t('policy86')}</div>
                    <div class="main-text">{t('policy87')}</div>
                    <div class="main-text">{t('policy88')}</div>
                    <div class="main-text">{t('policy89')}</div>
                </div>
            </div>
        </main>
    );
}

export default Policy;