import React, {useContext, useEffect, useState} from "react";
import useMainService from "../../services/MainService";
import {DateTime} from "luxon";
import {setLocalStorageWithExpiry} from "../../services/setLocalStorageWithExpiry";
import Modal from "../modal/Modal";
import {Google, Telegram} from "../../images/socials/negative";
import Button from "../button/Button";
import {useInput} from "../../hooks/input/input.hook";
import {AuthContext} from "../../providers/AuthProvider";
import {useTranslation} from "react-i18next";

const LoginModal = ({open, setOpen, setModal}) => {
    const {t} = useTranslation();
    const [loginError, setLoginError] = useState(false);
    const authContext = useContext(AuthContext);

    const {getCurrentUser, authWithVk, clearError, error} = useMainService();

    const auth = () => {
        const checkPassword = (pass) => {
            const regEx = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-zA-Z\d]).{6,}$/;
            return regEx.test(pass);
        };

        const isValidUsername = username !== '';
        const isValidPassword = checkPassword(password);


        if (isValidUsername && isValidPassword) {
            getCurrentUser(username, password)
                .then(data => {
                    if (data) {
                        const expiryDate = DateTime.fromISO(data.expired, {zone: 'utc'}).setZone('local');
                        setLocalStorageWithExpiry('token', data.token, expiryDate.toISO());
                        setOpen(false);
                        authContext.setIsAuth(true);
                        setLoginError(false);
                    }
                })
                .catch(log => {
                    setLoginError(true); // Устанавливаем ошибку при неудачной авторизации
                });
            clearError();
        } else {
            setLoginError(true); // Устанавливаем ошибку, если валидация не прошла
        }
    };

    //Функция авторизации по нажатию Enter
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            auth();
        }
    };

    const [username, usernameInput] =
        useInput({type: 'text', theme: t("enter-login"), extraClass: 'line', onKeyDown: handleKeyDown});
    const [password, passwordInput] =
        useInput({type: 'password', theme: t("enter-password"), extraClass: 'line', onKeyDown: handleKeyDown});

    //Авторизация через VK. Временно отключена.
    // const linkToVk = (url) => {
    //     window.location.href = url;
    // };


    //
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                auth();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [username, password]);

    return (
        <Modal extraClass={'small'} open={open} onClose={() => {setOpen(false)}}>
            <div className="modal__title">{t("auth-header")}</div>
            <div className="box">
                <div
                    className="modal__icon small"
                    // Авторизация через VK
                    // onClick={() => {
                    //     authWithVk()
                    //         .then(linkToVk)
                    //         .catch(e => console.log(e));
                    // }}
                >
                    <img src={Google} alt="Google"/>
                </div>
                <div className="modal__icon small">
                    <img src={Telegram} alt="Telegram"/>
                </div>
            </div>

            {loginError && <div className="modal__error">{t("auth-error")}</div>}

            {usernameInput}
            {passwordInput}

            <div className="modal__link end">{t("forgot-password")}</div>

            <Button
                onClick={() => auth()}
                extra={"btn btn-light line"}
                text={t("button-authorization")}/>

            <div className="modal__link" onClick={() =>setModal()}>{t("no-account")}</div>
        </Modal>
    );
};

export default LoginModal;
