import { useEffect, useMemo, useState } from "react";
import BoughtCard from "../boughtCard/BoughtCard";
import { Pubg } from "../../images/backgrounds";
import useMainService from "../../services/MainService";
import { useBasketService } from "../../services/BasketService";
import { useTranslation } from "react-i18next";

const Tags = (props) => {
    const { desc, id, instruction } = props;
    const [active, setActive] = useState(0);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const { error, getAllProducts, getAllProductsById } = useMainService();
    const { getActualBasket, createBasket, checkBasket, addItemToBasket, removeItemToBasket } = useBasketService();
    const { t } = useTranslation();

    useEffect(() => {
        if (id) {
            setLoading(true);
            getAllProductsById(id)
                .then(onProductsLoaded)
                .catch(log => console.log(log));
        }
    }, [id]);

    const onProductsLoaded = (prods) => {
        setLoading(false);
        setProducts([...prods]);
    }

    const discountGames = [
        "Mobile Legends",
        "PSN Card United States",
        "Xbox Live Gift Card",
        "Steam Wallet Code TL - (Turkey)",
        "Fortnite",
    ]

    const children = useMemo(() => (products.map((item, index) => (
        <BoughtCard
            itemId={item.id}
            key={index}
            oldPrice={item.priceWithDiscount + (discountGames.includes(item.gameName) ? 10 : 0)}
            count={0}
            src={item.images ? item.images : Pubg}
            text={item.name}
            price={item.price} />
    ))), [products]);

    return (
        <div className="tag">
            <div className="tag__header">
                <div className="sort">
                    <div onClick={() => setActive(0)} className={"sort__item " + (active == 0 ? "active" : "")}>
                        <div className="sort__text">
                            {t('buy')}
                        </div>
                    </div>
                    <div onClick={() => setActive(1)} className={"sort__item " + (active == 1 ? "active" : "")}>
                        <div className="sort__text">
                            {t('desc')}
                        </div>
                    </div>
                    <div onClick={() => setActive(2)} className={"sort__item " + (active == 2 ? "active" : "")}>
                        <div className="sort__text">
                            {t('instruction')}
                        </div>
                    </div>
                </div>
            </div>
            {id ? <div className="tag__body">
                <div className={"tag__item " + (active == 0 ? "active" : "")}>
                    <div className="frame">
                        {console.log(children)}
                        {children}
                    </div>
                </div>
                <div className={"tag__item " + (active == 1 ? "active" : "")}>
                    <div className="frame">
                        <div className="tag__info">
                            <div className="tag__title">{t("instruction")}</div>
                            <div className="tag__text" dangerouslySetInnerHTML={{ __html: desc }}></div>
                        </div>
                    </div>
                </div>
                <div className={"tag__item " + (active == 2 ? "active" : "")}>
                    <div className="frame">
                        <div className="tag__info">
                            <div className="tag__title">{t('instruction')}</div>
                            <div className="tag__text" dangerouslySetInnerHTML={{ __html: instruction }}></div>
                        </div>
                    </div>
                </div>
            </div> : null}
        </div >
    )
}

export default Tags;