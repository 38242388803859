import { useTranslation } from "react-i18next";

const Uslovie = (props) => {
    const { t } = useTranslation();

    return (
        <main className="main">
            <div className="information">
                <div className="information__row">
                    <div className="news-title">{t('use')}</div>
                    <div class="news-title space">{t('use1')}</div>
                    <div class="main-text">{t('use11')}</div>
                    <div class="main-text">{t('use12')}</div>
                    <div class="main-text">{t('use122')}</div>
                    <div class="main-text">{t('use123')}</div>
                    <div class="main-text">{t('use124')}</div>
                    <div class="main-text">{t('use125')}</div>
                    <div class="main-text">{t('use126')}</div>
                    <div class="main-text">{t('use13')}</div>
                    <div class="main-text">{t('use131')}</div>
                    <div class="main-text">{t('use132')}</div>
                    <div class="main-text">{t('use133')}</div>
                    <div class="main-text">{t('use134')}</div>
                    <div class="news-title space">{t('use2')}</div>
                    <div class="main-text">{t('use21')}</div>
                    <div class="main-text">{t('use22')}</div>
                    <div class="news-title space">{t('use3')}</div>
                    <div class="main-text">{t('use31')}</div>
                    <div class="main-text">
                        {t('use32')}</div>
                    <div class="main-text">{t('use33')}</div>
                    <div class="news-title space">{t('use4')}</div>
                    <div class="main-text">{t('use41')}</div>
                    <div class="main-text">{t('use42')}</div>
                    <div class="main-text">{t('use43')}</div>
                    <div class="news-title space">{t('use5')}</div>
                    <div class="main-text">{t('use51')}</div>
                    <div class="main-text">{t('use52')}</div>
                    <div class="main-text">{t('use53')}</div>
                    <div class="news-title space">{t('use6')}</div>
                    <div class="main-text">{t('use61')}</div>
                    <div class="main-text">{t('use62')}</div>
                    <div class="main-text">{t('use63')}</div>
                    <div class="main-text">{t('use64')}</div>
                    <div class="main-text">{t('use65')}</div>
                    <div class="main-text">{t('use66')}</div>
                    <div class="main-text">{t('use67')}</div>
                    <div class="main-text">{t('use68')}</div>
                    <div class="main-text">
                        {t('use-text')}</div>

                </div>
            </div>
        </main>
    );
}

export default Uslovie;