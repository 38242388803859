import { Link, useParams } from "react-router-dom";
import { Like as LikeIcon, LikeHover as LikeIconHover, LikeRed } from "../../images/system";
import { useContext, useState } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import { PopupContext } from "../../providers/PopupProvider";

const GameCard = (props) => {
    const [like, setLike] = useState(false);
    const { id, name, oldPrice, price, big, src, key, isMouseDown } = props;
    const { isAuth } = useContext(AuthContext);
    const { setShow } = useContext(PopupContext);

    const handleLink = (e) => {
        if (isMouseDown) {
            e.preventDefault();
        }
    }

    return (
        <Link to={`/catalog/${id}`} key={key} className={"game-card " + (big ? "no-hover big" : "")}
            onClick={handleLink} draggable="false">
            <div className="game-card__img">
                <img src={src ? src : ''} alt="" draggable="false" />
            </div>
            <div className="game-card__row">
                <div className="game-card__content">
                    <div className="game-card__title">{name}</div>
                    <div className="game-card__price">
                        <div className="game-card__price-old">{oldPrice ? (oldPrice !== price ? oldPrice : '') : ''}</div>
                        <div className="game-card__price-now">{price}</div>
                        <div className="game-card__price-value">$</div>
                    </div>
                </div>
                <div className="game-card__notifications">
                    {/* <div className="game-card__notification indicator">
                        <div className="indicator__text">+10%</div>
                    </div>
                    <div className="game-card__notification indicator-yellow">
                        <div className="indicator__text">-10%</div>
                    </div> */}
                </div>
                <div onClick={(e) => { e.preventDefault(); if (isAuth) setLike(like => !like); else setShow(true); }} className="game-card__fav arrow">
                    <div className="arrow-content">
                        <div className="arrow-icon">
                            <img className="arrow-icon__normal" src={!like ? LikeIcon : LikeRed}
                                alt="add favourite game" />
                            <img className="arrow-icon__hover" src={!like ? LikeIcon : LikeRed}
                                alt="add favourite game" />
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default GameCard;