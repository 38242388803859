import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    items: [],
    sum: 0,
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setItems: (state, action) => {
            state.items = action.payload;
        },
        addItem: (state, action) => {
            const itemIndex = state.items.findIndex(item => item.productId === action.payload.productId);
            if (itemIndex >= 0) {
                state.items[itemIndex].count += action.payload.count;
            } else {
                state.items.push(action.payload);
            }
        },
        removeItem: (state, action) => {
            state.items = state.items.filter(item => item.productId !== action.payload);
        },
        setSum: (state) => {
            state.sum = state.items.reduce((acc, item) => acc + item.price, 0);
        },
        updateItemCount: (state, action) => {
            const { productId, count } = action.payload;
            const item = state.items.find(item => item.productId === productId);
            if (item) {
                item.newCount = count;
            }
        }
    }
});

export const { setItems, addItem, removeItem, setSum, updateItemCount } = cartSlice.actions;
export default cartSlice.reducer;
