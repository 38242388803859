import { Link } from "react-router-dom";
import Menu from "../menu/Menu";
import { useEffect } from "react";

const NavigationItem = (props) => {
    const { src, to, srcHover, number, alt, onClick } = props;

    const notification = number != null && number > 0 ? <Notification number={number} /> : null

    const element = to ? <Link to={to ? to : ""} className="navigation__item">
        <div className="navigation__icon">
            <img className="navigation__icon-normal" src={src} alt={alt} />
            <img className="navigation__icon-hover" src={srcHover} alt={alt} />
        </div>
        {notification}
    </Link> : <div className="navigation__item" onClick={onClick}>
        <div className="navigation__icon">
            <img className="navigation__icon-normal" src={src} alt={alt} />
            <img className="navigation__icon-hover" src={srcHover} alt={alt} />
        </div>
        {notification}
    </div>;
    return (
        <>
            {element}
        </>
    );
}

const Notification = ({ number }) => {
    
    return (
        <div className="navigation__notification notification">
            {number}
        </div>
    )
}

export default NavigationItem;