import {Routes, Route, HashRouter} from "react-router-dom";

import Header from "../header/Header";
import Footer from "../footer/Footer";

import "../../scss/style.scss";
import { About, Home, Cart, Catalog, Categories, EditProfile, Favourites, GameInfo, History, NewsInfo, News, Payment, Policy, ProfilePage, Support, Uslovie, MenuProfile, Subscribes, PaymentTalk, Discounts, CatalogGames } from "../../pages";
import { BasketProvider } from "../../providers/BasketProvider";
import Disclaimer from "../disclaimer/Disclaimer";
import { PopupProvider } from "../../providers/PopupProvider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AuthProvider from "../../providers/AuthProvider";

import {CartProvider} from "../../providers/CartProvider";


const App = () => {
    return (
        <HashRouter>
            <AuthProvider>
                <PopupProvider>
                    <BasketProvider>
                        <CartProvider>
                        {/*<Disclaimer />*/}
                        <Header />
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/cart" element={<Cart />} />
                            <Route path="/catalog" element={<Catalog />} />
                            <Route path="/games" element={<CatalogGames />} />
                            <Route path="/subscribes" element={<Subscribes />} />
                            <Route path="/discounts" element={<Discounts />} />
                            <Route path="/catalog/:id" element={<GameInfo />} />
                            <Route path="/categories" element={<Categories />} />
                            <Route path="/edit-profile" element={<EditProfile />} />
                            <Route path="/favourites" element={<Favourites />} />
                            <Route path="/history" element={<History />} />
                            <Route path="/news-info" element={<NewsInfo />} />
                            <Route path="/news" element={<News />} />
                            <Route path="/payment" element={<Payment />} />
                            <Route path="/payment-talk" element={<PaymentTalk />} />
                            <Route path="/policy" element={<Policy />} />
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route path="/support" element={<Support />} />
                            <Route path="/uslovie" element={<Uslovie />} />
                            <Route path="/menu" element={<MenuProfile />} />
                        </Routes>
                        <Footer />
                        </CartProvider>
                    </BasketProvider>
                </PopupProvider>
            </AuthProvider>
        </HashRouter>
    )
}

export default App;