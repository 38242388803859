import React, { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { X, XHover } from "../../images/system";
import RoundButton from "../roundButton/RoundButton";

const Modal = (props) => {
    const element = useMemo(() => document.createElement("span"), []);
    const rootElement = document.getElementById("modal");
    const { open, big, onClose, extraClass } = props;

    useEffect(() => {
        if (open) {
            rootElement.appendChild(element);

            return () => {
                rootElement.removeChild(element);
            };
        }
    }, [open, element, rootElement]);

    return open ? createPortal(
        <View big={big} onClose={onClose} extraClass={extraClass}>
            {React.Children.map(props.children, child => {
                return child ? React.cloneElement(child) : null;
            })}
        </View>, element) : null;
}

const View = (props) => {
    const { big, onClose, extraClass } = props;

    return (
        <section className={"modal " + (big ? "big" : "")}>
            <div className={"modal__row" + (extraClass ? (" " + extraClass) : "")}>
                <div className="modal__head">
                    <div className="caption">
                        <div className="caption__manage"></div>
                        <div className="caption__middle"></div>
                        <div className="caption__manage">
                            <RoundButton onClick={onClose} src={X} srcHover={XHover} />
                        </div>
                    </div>
                </div>
                <div className="modal__body">
                    {React.Children.map(props.children, child => {
                        return child ? React.cloneElement(child) : null;
                    })}
                </div>
            </div>
        </section>
    );
}

export default Modal;
