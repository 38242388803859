import { useHttp } from "../hooks/http/http.hook";
import { getLocalStorageWithExpiry } from "./getLocalStorageWithExpiry";

const useMainService = () => {
    const { loading, request, error, clearError } = useHttp();

    const _apiBase = "https://api.platformone.store";
    const pageSize = 20;
    const totalSize = 180;

    const getActualBasket = async () => {
        try {
            const token = getLocalStorageWithExpiry('token');
            const response = await fetch(`${_apiBase}/api/v1/user/basket/actual`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.replace(/"/g, '')}`
                }
            });

            // Логирование статуса ответа
            console.log('Ответ из getActualBasket:', response.status);

            if (response.status === 204) { // No Content
                console.warn('No content in response');
                return null;
            }

            const text = await response.text();

            if (!text) {
                console.warn('Received an empty response.');
                return null;
            }

            const data = JSON.parse(text);
            console.log('Parsed JSON data:', data);

            return data.id || '';
        } catch (error) {
            console.error('Error fetching actual basket:', error);
            return '';
        }
    };

    const getAllBaskets = async () => {
        const token = getLocalStorageWithExpiry('token');
        if (token)
            return await request(`${_apiBase}/api/v1/user/baskets`, 'GET', null,
                { "Content-Type": "application/json", "Authorization": `Bearer ${token.replace('"', '')}` });
        return null;
    }

    const getAnswerTalkBank = async (id) => {
        const token = getLocalStorageWithExpiry('token');
        if (token)
            return await request(`${_apiBase}/api/v1/payment/talkbank/${id}`, 'POST', null,
                { "Content-Type": "application/json", "Authorization": `Bearer ${token.replace('"', '')}` });
        return null;
    }

    const getKassa24Link = async (basketId) => {
        console.log("getKassa24Link method is work!")
        const token = getLocalStorageWithExpiry('token');
        if (token) {
            try {
                const response = await request(
                    `${_apiBase}/api/v1/payment/kassa24/${basketId}`,
                    'POST',
                    null,
                    {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token.replace('"', '')}`
                    },
                );
                console.log("Response is -> ",response) // срабатывает

                // Проверяем, что данные корректны
                if (!response || !response.link_url) {
                    console.log("Ошибка ответа Kassa24 -> ",response)
                    return;
                }
                console.log('Response from backend');
                return response;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        return null;
    };

    const getRecomendations = async () => {
        return await request(`${_apiBase}/api/v1/Recomendation`, 'GET');
    }

    const getMe = async () => {
        const token = getLocalStorageWithExpiry('token');
        if (token)
            return await request(`${_apiBase}/api/v1/user/info`, 'GET', null,
                { "Content-Type": "application/json", "Authorization": `Bearer ${token.replace('"', '')}` });
        return null;
    }

    const getFavourites = async () => {
        const token = getLocalStorageWithExpiry('token');
        if (token)
            return await request(`${_apiBase}/api/v1/favorites`, 'GET', null,
                { "Content-Type": "application/json", "Authorization": `Bearer ${token.replace('"', '')}` });
        return null;
    }

    const saveFavourite = async (product) => {
        const token = getLocalStorageWithExpiry('token');
        if (token) {
            return await request(`${_apiBase}/api/v1/favorites`, 'POST', JSON.stringify(product),
                { "Content-Type": "application/json", "Authorization": `Bearer ${token.replace('"', '')}` }, false);
        }
        return null;
    }

    const deleteFavourite = async (id) => {
        const token = getLocalStorageWithExpiry('token');
        if (token)
            return await request(`${_apiBase}/api/v1/favorites/${id}`, 'DELETE', null,
                { "Content-Type": "application/json", "Authorization": `Bearer ${token.replace('"', '')}` }, false);
        return null;
    }

    const createBasket = async (items) => {
        try {
            const token = getLocalStorageWithExpiry('token');
            console.log('Sending data to API:', items); //работает
            const response = await fetch(`${_apiBase}/api/v1/basket/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.replace(/"/g, '')}`
                },
                body: JSON.stringify(items)
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response from server:', errorText);
                throw new Error(`Could not create basket, status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Basket created in MainService:', data); //работает
            return data;
        } catch (error) {
            console.error('Error creating basket in MainService:', error);
            return null;
        }
    };

    const checkBasket = async (id) => {
        try {
            const token = getLocalStorageWithExpiry('token');
            const response = await fetch(`${_apiBase}/api/v1/basket/check/${id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token.replace(/"/g, '')}`
                }
            });

            if (!response.ok) {
                throw new Error(`Could not fetch ${response.url}, status: ${response.status}`);
            }

            if (response.status !== 204) { // 204 No Content
                const data = await response.json();
                console.log('Basket checked successfully:', data); //работает
                return data;
            } else {
                console.warn("No content in response");
                return null;
            }

        } catch (error) {
            console.error("Ошибка при выполнении запроса:", error);
            return null;
        }
    };

    const addItemToBasket = async (id, productId, count) => {
        try {
            const token = getLocalStorageWithExpiry('token');
            if (token) {
                const cleanedToken = token.replace(/"/g, ''); // Удаление всех кавычек, если они присутствуют
                const response = await request(
                    `${_apiBase}/api/v1/basket/item/add/${id}/${productId}/${count}`,
                    'PUT',
                    null,
                    {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${cleanedToken}`
                    }
                );
                console.log('Item added to basket successfully:', response); // работает
                return response; // Возвращаем результат запроса, если все прошло успешно
            } else {
                console.error("Token is missing or expired.");
                return null; // Токен отсутствует или истек
            }
        } catch (error) {
            console.error("Error adding item to basket:", error); // Логирование ошибки
            return null; // null в случае ошибки
        }
    };

    const removeItemToBasket = async (id, productId, count) => {
        try {
            const token = getLocalStorageWithExpiry('token');
            if (!token) {
                throw new Error('Token not found');
            }

            const cleanedToken = token.trim().replace(/^"|"$/g, '');
            const response = await request(
                `${_apiBase}/api/v1/basket/item/remove/${id}/${productId}/${count}`,
                'PUT',
                null,
                {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${cleanedToken}`
                }
            );
            console.log("Remove item from basket is success!") // неработает
            return response;
        } catch (error) {
            console.error('Failed to remove item from basket:', error);
            return null;
        }
    };

    const getAllCategories = async () => {
        return await request(`${_apiBase}/api/v1/info/categories`, 'GET');
    }

    const getAllCountries = async () => {
        return await request(`${_apiBase}/api/v1/info/countries`, 'GET');
    }

    const getAllProducts = async (filter) => {
        let line = "?";
        if (filter.pageNumber) line += 'pageNumber=' + filter.pageNumber + '&';
        if (filter.pageSize) line += 'pageSize=' + filter.pageSize;
        else line += 'pageSize=' + pageSize;
        if (filter.category) line += '&categoryname=' + filter.category;
        if (filter.name) line += '&gamename=' + filter.name;
        if (filter.order) line += '&orderby=' + filter.order;
        return await request(`${_apiBase}/api/v1/products${line}`, 'GET');
    }

    const getAllSubscribes = async () => {
        return await request(`${_apiBase}/api/v1/products?typename=Subscription`, 'GET');
    }

    const getAllProductsById = async (id) => {
        return await request(`${_apiBase}/api/v1/products?gameid=${id}`, 'GET');
    }

    const getProduct = async (id) => {
        return await request(`${_apiBase}/api/v1/game/${id}`, 'GET');
    }

    const getProductById = async (id) => {
        return await request(`${_apiBase}/api/v1/products/${id}`, 'GET');
    }

    const getAllGames = async () => {
        return await request(`${_apiBase}/api/v1/game`, 'GET');
    }

    const getAllGamesByName = async (name) => {
        return await request(`${_apiBase}/api/v1/game?name=${name}`, 'GET');
    }

    const getAllProductsByName = async (name) => {
        return await request(`${_apiBase}/api/v1/products?gamename=${name}`, 'GET');
    }

    const getCurrentUser = async (username, password) => {
        return await request(`${_apiBase}/account/login`, 'POST', JSON.stringify({ username, password }), { "Content-Type": "application/json" });
    }

    const registerUser = async (username, email, password, countryId) => {
        return await request(`${_apiBase}/account/register/user`, 'POST', JSON.stringify({ username, email, password, countryId }), { "Content-Type": "application/json" }, false);
    }

    const authWithVk = async () => {
        return await request(`${_apiBase}/api/v1/vk/oauth`, 'GET', null, { "Content-Type": "text/plain", "charset": "utf-8" }, false);
    }

    const tokenWithVk = async (code) => {
        return await request(`${_apiBase}/api/v1/vk/token?code=${code}`, 'GET');
    }

    return { loading, error, pageSize, totalSize, getCurrentUser, registerUser, getAllProducts, getAllProductsByName, getAllCategories, getProduct, getAllCountries, createBasket, clearError, checkBasket, addItemToBasket, removeItemToBasket, getMe, getRecomendations, getAllProductsById, getAllSubscribes, getFavourites, saveFavourite, deleteFavourite, getAnswerTalkBank, getKassa24Link, getAllBaskets, getActualBasket, authWithVk, tokenWithVk, getProductById, getAllGames, getAllGamesByName };
}
export default useMainService;
