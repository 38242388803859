import { useTranslation } from "react-i18next";
import "../scss/news.scss";

const About = () => {
    const {t} = useTranslation();
    
    return (
        <div className="main">
            <div className="information">
                <div className="information__row">
                    <div className="news-title">{t('hello')}</div>
                    <div className="main-text">{t('about-text')}</div>

                    <div className="frame">
                        <div className="frame__title">{t('mission')}</div>
                        <div className="frame__text main-text">{t('mission-text')}</div>
                    </div>

                    <div className="frame">
                        <div className="frame__title">{t('history')}</div>
                        <div className="frame__text main-text">{t('history-text1')}</div>
                        <div className="frame__text main-text">{t('history-text2')}</div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default About;