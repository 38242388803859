import React, {useState} from "react";
import {EditPencil} from "../../images/system";
import "../../scss/profile.scss";
import Input from "../input/Input";
import {RightLong} from "../../images/arrows";
import Button from "../button/Button";
import Modal from "../modal/Modal";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Payform = (props) => {
    const [promo, setPromo] = useState(false);
    const {sum, discount, comission, modal, page, pageSrc, starter, action} = props;
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();
    console.log("Sum in Payform -> ", sum)

    const field = promo ?
        <Input theme={t("promocode")} error={t("promocode-error")} check={err => err.toUpperCase() === "PROMOCODE64"}
               btn={true} src={RightLong}/> :
        (<div onClick={() => setPromo(true)} className="payform__link">
            <p>
                {t('promocode-add')}
            </p>

            <img src={EditPencil} alt="edit pencil"/>
        </div>)

    return (
        <div className="payform">
            <div className="payform__info">

                {/*Сумма*/}
                <div className="payform__content extra">
                    <div className="payform__text">{t('amount')}</div>
                    <div className="payform__price">
                        <p>{(sum).toFixed(2)}</p>
                        <span>$</span>
                    </div>
                </div>

                {comission ? <div className="payform__content extra">
                    <div className="payform__text">{t('comission')}</div>
                    <div className="payform__price">
                        <p>{comission}</p>
                        <span>$</span>
                    </div>
                </div> : null}

                {discount ? <div className="payform__content extra">
                    <div className="payform__text">{t('amount')}</div>
                    <div className="payform__price">
                        <p>- {(sum * discount / 100).toFixed(2)}</p>
                        <span>$</span>
                    </div>
                </div> : null}

                {/*Сумма товаров*/}
                <div className="payform__content">
                    <div className="payform__text">{t('amount-games')}</div>
                    <div className="payform__price">
                        <p>{((sum + (comission ? comission : 0)) - (sum * (discount ? discount : 0) / 100)).toFixed(2)}</p>
                        <span>$</span>
                    </div>
                </div>

                {/*У меня есть промокод*/}
                <div className="payform__frame">
                    {field}
                </div>

            </div>
            <div className="payform__content">

                {/*Кнопка "Перейти к оплате"*/}
                <Button
                    onClick={() => {
                        if (starter) starter();
                        if (page) {
                            action();
                        } else
                            modal ? setOpen(true) : navigate("/payment")
                    }} text={t("to-payment")} extra={"btn-light"}/>

                {modal ? <Modal open={open} onClose={() => {
                    setOpen(false)
                }}>
                    {React.Children.map(props.children, child => {
                        return React.cloneElement(child);
                    })}
                </Modal> : null}
            </div>
        </div>
    );
}

export default Payform;