import List from "../components/list/List";
import NavbarItem from "../components/navbarItem/NavbarItem";

import EldenRing from "../images/backgrounds/elden-ring-game-card.webp";

import { Brom, BromHover, Star, StarNoBorder, X, XHover } from "../images/system";

import { HomeIcon, HomeIconHover, MultiIcon, MultiHoverIcon, SingleIcon, SingleHoverIcon, ActionIcon, ActionHoverIcon, ShooterIcon, ShooterHoverIcon, AdventureIcon, AdventureHoverIcon, RoleplayIcon, RoleplayHoverIcon, SimulatorIcon, SimulatorHoverIcon, StrategyIcon, StrategyHoverIcon, ArcadeIcon, ArcadeHoverIcon, SportIcon, SportHoverIcon, HorrorIcon, HorrorhoverIcon, OpenIcon, OpenHoverIcon, NarrativeIcon, NarrativeHoverIcon } from "../images/category";
import FilterSort from "../components/filterSort/FilterSort";
import GameForm from "../components/gameForm/GameForm";
import Sidebar from "../components/sidebar/Sidebar";
import SidebarItem from "../components/sidebarItem/SidebarItem";
import Choices from "../components/choices/Choices";
import ChoiceItem from "../components/choiceItem/ChoiceItem";
import "../scss/catalog.scss";
import CategoriesList from "../components/categoriesList/CategoriesList";
import { useLocation, useParams } from "react-router-dom";
import useMainService from "../services/MainService";
import { useEffect, useMemo, useState } from "react";
import Button from "../components/button/Button";
import { Pubg } from "../images/backgrounds";
import { useTranslation } from "react-i18next";

const Catalog = () => {
    const games = [
        {
            name: "Elden ring",
            oldPrice: "3 990",
            price: "3 990",
            src: EldenRing,
            indicators: [
                {
                    number: "+10",
                    type: 1
                },
                {
                    number: "-10",
                    type: 2
                },
            ]
        }
    ]

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const name = searchParams.get('name');
    const category = searchParams.get('categoryname');

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortState, setSortState] = useState(0);
    const { error, getAllProducts, pageSize, totalSize, getAllGames, getAllGamesByName } = useMainService();

    useEffect(() => {
        setLoading(true);
        if (name) getAllGamesByName(name).then(onProductLoad).catch(err => console.log(err));
        else getAllGames().then(onProductLoad).catch(err => console.log(err))
    }, [category, name, sortState, currentPage])

    const onProductLoad = (prods) => {
        console.log(prods);
        setLoading(false);
        setProducts(prods);
    }

    const elems = [
        { value: "По убыванию цены", state: 1 },
        { value: "По возрастанию цены", state: 2 }
    ];

    const children = useMemo(() => (products.map((item, index) =>
    (<GameForm
        key={item.id}
        src={item.images ? item.images.mainframe ? item.images.mainframe : EldenRing : Pubg}
        id={item.id}
        name={item.name}
        extraClass={"videogames__item"}
        indicators={[]} />))), [products]);

    const showMoreGames = () => {
        setCurrentPage(currentPage => (currentPage + 1));
    }

    const {t} = useTranslation();

    // const showMoreBtn = useMemo(() => (
    //     currentPage * pageSize < totalSize ? <div className="sides__field">
    //         <Button extra={"btn-strong"} text={"Показать еще"} onClick={showMoreGames} />
    //     </div> : null
    // ), [products]);

    return (
        <>
            <div className="main">
                <CategoriesList />

                <div className="main__container">
                    <div className="filter">
                        <div className="filter__row">
                            <div className="filter__title title">{category ? category : name ? name : t('catalog-link')}</div>
                            <FilterSort
                                classes={"filter__sort"}
                                values={elems}
                                setSortState={setSortState} />
                        </div>
                    </div>
                    <div className="sides">
                        <div className="sides__row">
                            <div className="sides__container">
                                <div className="videogames">
                                    <div className="videogames__row">
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Catalog;