import { useState } from "react";
import Modal from "../components/modal/Modal";
import Payform from "../components/payform/Payform";
import PaymentItem from "../components/paymentItem/PaymentItems";
import { Bank } from "../images/payment";
import Button from "../components/button/Button";
import useMainService from "../services/MainService";
import { useTranslation } from "react-i18next";
import {useCart} from "../providers/CartProvider";

const Payment = () => {
    const { getKassa24Link } = useMainService();
    const [kassa24Link, setKassa24Link] = useState(null); // состояние для ссылки
    const [current, setCurrent] = useState(0);
    const [errorMessage, setErrorMessage] = useState(''); // состояние для сообщения об ошибке
    const [isModalOpen, setIsModalOpen] = useState(false); // состояние для модального окна
    const { sum } = useCart();

    const { t } = useTranslation();

    console.log("")
    const payments = [
        {
            src: Bank,
            title: t("payment-by-card"),
            // text: "",
            // page: false,
            // pageSrc: '',
            action: () => {
                console.log("Оплата через Kassa24"); // срабатывает
                let basketId = localStorage.getItem('basketId');
                console.log("basketId -> ",basketId) //срабатывает
                if (basketId) {
                    getKassa24Link(basketId)
                        .then(data => {
                            console.log("Данные getKassa24Link -> ",data)//приходит объект
                            if (data && data.link_url) {
                                setKassa24Link(data.link_url); // Сохраняем ссылку в состоянии
                                setErrorMessage(''); // Сбрасываем сообщение об ошибке при успешном получении данных
                                setIsModalOpen(false); // Закрываем модальное окно, если ошибка устранена
                            } else {
                                setErrorMessage(t("payment-text3"));
                                setIsModalOpen(true); // Открываем модальное окно при ошибке
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            setErrorMessage('Произошла ошибка при получении данных. Пожалуйста, попробуйте снова.');
                            setIsModalOpen(true); // Открываем модальное окно при ошибке
                        });
                } else {
                    setErrorMessage('Не удалось получить идентификатор корзины.');
                    setIsModalOpen(true); // Открываем модальное окно при ошибке
                }
            }
        },
    ];

    const handleMoreDetailsClick = () => {
        if (kassa24Link) {
            window.location.href = kassa24Link; // Редирект на ссылку Kassa24
        } else {
            setErrorMessage('Ссылка на оплату отсутствует. Пожалуйста, попробуйте снова.');
            setIsModalOpen(true); // Открываем модальное окно при ошибке
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Закрываем модальное окно
    };

    return (
        <main className="main">
            <div className="main__container">
                <div className="filter">
                    <div className="filter__row">
                        <div className="filter__title title">{t('payment')}</div>
                    </div>
                </div>
                <div className="window">
                    <div className="window__row">
                        <div className="window__big">
                            <div className="payments">
                                {payments.map((item, index) => (
                                    <PaymentItem
                                        onClick={() => {
                                            setCurrent(index);
                                            item.action(); // вызываем action для получения ссылки
                                        }}
                                        key={index}
                                        src={item.src}
                                        title={item.title}
                                        text={item.text}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="window__side">

                            <Payform
                                sum={sum}
                                // discount={}
                                // comission={}
                                modal
                                page={payments[current].page}
                                pageSrc={payments[current].pageSrc}
                                action={payments[current].action}
                            >
                                <div className="modal__frame">
                                    <div className="modal__icon middle">
                                        <img src={payments[current].src} alt="bank" />
                                    </div>

                                    <div className="modal__content">
                                        <div className="modal__title">
                                            {t('payment-text1')}
                                            {/*{payments[current].title}*/}
                                        </div>
                                        <div className="modal__text">{t('payment-text2')}</div>
                                        <Button
                                            text={t("to-payment")}
                                            extra={"btn-medium"}
                                            onClick={handleMoreDetailsClick} // обрабатываем нажатие на кнопку
                                        />
                                    </div>

                                    <div className="modal__text">{t('number-order')} – 12003213021</div>
                                </div>
                            </Payform>

                            {/* Модальное окно для отображения ошибок */}
                            <Modal open={isModalOpen} onClose={handleCloseModal} >
                                <div className="modal__content" style={{color:"white"}}>
                                    <h2 style={{color:"red"}}>{t("not-payed")}</h2>
                                    <p>{errorMessage}</p>
                                    <Button
                                        text={t("button-close-title")}
                                        extra={"btn-medium"}
                                        onClick={handleCloseModal}
                                    />
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Payment;
