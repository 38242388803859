import Plus from "../../images/status/plus.svg";
import Decrease from "../../images/status/decrease.svg";
import { useContext, useEffect, useState } from "react";
import Button from "../button/Button";
import { Cart, RefreshBlue } from "../../images/system";
import { AuthContext } from "../../providers/AuthProvider";
import useMainService from "../../services/MainService";
import { useBasketService } from "../../services/BasketService";
import { Pubg } from "../../images/backgrounds";
import { useTranslation } from "react-i18next";
import {useCart} from "../../providers/CartProvider";

const CartItem = (props) => {
    const { items, setItems } = useCart();
    const [count, setCount] = useState(props.startCount || 1);
    const [seconds, setSeconds] = useState(5);
    const [product, setProduct] = useState(null);
    const { productId, deleted } = props;
    const { isAuth } = useContext(AuthContext);
    const { getProductById } = useMainService();
    const { addItemToBasket, removeItemToBasket, checkBasket } = useBasketService();
    const { t } = useTranslation();

    const updateBasket = async (basketId) => {
        const updatedBasket = await checkBasket(basketId);
        if (updatedBasket && Array.isArray(updatedBasket.basketItems)) {
            setItems(updatedBasket.basketItems);
        }
    };

    const handleChangeCount = async (newCount) => {
        setCount(newCount);

        const basketId = localStorage.getItem('basketId');
        if (basketId && newCount !== 0) {
            const currentItem = items.find(item => item.productId === productId);
            const currentCount = currentItem ? currentItem.count : 0;
            const difference = newCount - currentCount;

            try {
                if (difference > 0) {
                    await addItemToBasket(basketId, productId, difference);
                } else {
                    await removeItemToBasket(basketId, productId, Math.abs(difference));
                }
                updateBasket(basketId);
            } catch (error) {
                console.error("Error updating item quantity on server:", error);
            }
        }

        if (newCount === 0) {
            setSeconds(5);
            startDeleteTimer();
        }
    };

    const startDeleteTimer = () => {
        const timer = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds <= 1) {
                    clearInterval(timer);
                    deleteItem();
                    return 0;
                }
                return prevSeconds - 1;
            });
        }, 1000);
    };

    const deleteItem = async () => {
        const basketId = localStorage.getItem('basketId');
        if (basketId) {
            try {
                await removeItemToBasket(basketId, productId, 1);
                updateBasket(basketId);
            } catch (error) {
                console.error(`Error removing product ${productId}:`, error);
            }
        }
    };

    const restoreItem = async () => {
        setCount(1);
        const basketId = localStorage.getItem('basketId');
        if (basketId) {
            await addItemToBasket(basketId, productId, 1);
            updateBasket(basketId);
        }
    };

    useEffect(() => {
        getProductById(productId)
            .then(data => setProduct(data))
            .catch(err => console.error(err));
    }, [productId]);

    const button = count === 0 ?
        <Button text={t("add")} src={Cart} extra={"btn-strong"} onClick={() => { if (isAuth) handleChangeCount(1) }} /> :
        (<div className="counter">
            <div onClick={() => handleChangeCount(count > 0 ? count - 1 : 0)} className="counter__icon">
                <img src={Decrease} alt="decrease count" />
            </div>
            <div className="counter__text">{count}</div>
            <div onClick={() => handleChangeCount(count + 1)} className="counter__icon plus">
                <img src={Plus} alt="increase count" />
            </div>
        </div>);

    return (
        <div className={"bought-card" + (count === 0 ? " deleted" : "")}>
            <div className="bought-card__img">
                <img src={product?.images || Pubg} alt="" />
            </div>

            <div className="bought-card__content">
                <div className="bought-card__text">{product?.name || ""}</div>
                <section className="path">
                    <div className="path__row">
                        {product?.priceWithDiscount && product.priceWithDiscount !== product.price &&
                            <div className="bought-card__text old">{product.priceWithDiscount.toFixed(2)}</div>}
                        <div className="bought-card__text">{product?.price || ""}</div>
                        <div className="path__link">$</div>
                    </div>
                </section>
            </div>

            <div className="bought-card__btn">
                {button}
            </div>

            {count === 0 && seconds > 0 && (
                <div className="bought-card__deleted" onClick={restoreItem}>
                    <p>{t('cancel')} {seconds}..</p>
                    <img src={RefreshBlue} alt="cancel deletion" />
                </div>
            )}
        </div>
    );
}

export default CartItem;
